///
/// GENERAL CONTENT
///

.mat-progress-bar {
	position: absolute;
	z-index: 9999;
}

.container {
	display: flex;
	flex-direction: column;
	height: 100%;
	position: relative;
	margin: 0 auto;
	padding: 1.1rem 2rem 0 2rem;
}

th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
	padding: 0 0.5rem !important;
	font-size: var(--text-size-small);
	white-space: nowrap;

	&:first-of-type {
		padding-left: 24px !important;
	}
}

// MATERIAL CARDS
.mat-card.table-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin-bottom: 2rem;
	padding: 0;
	overflow: hidden;

	> div {
		width: 100%;
		overflow-x: auto;
	}

	table {
		display: table;
		width: 100%;

		thead,
		tbody,
		tfoot {
			width: 100%;
		}

		thead {
			display: table-header-group;

			tr:nth-child(2) {
				.mat-table-sticky {
					top: 48px !important;
				}
			}
		}

		tbody {
			display: table-row-group;

			tr:nth-child(even) {
				background-color: #fafafa;
			}

			tr:last-of-type {
				td {
					border-bottom: none;
				}
			}
		}

		tfoot {
			display: table-footer-group;
		}

		tr {
			width: 100%;
		}

		button {
			margin-left: 0.5rem;
		}
	}

	.mat-toolbar {
		margin-top: auto;
		background: #fff;
		border-top: 1px solid rgba(0, 0, 0, 0.12);

		&-row {
			height: 100%;
		}

		.buttons {
			display: flex;
			gap: 1rem;
			align-items: center;
			align-content: center;

			.progress-wrapper {
				.mat-form-field-wrapper {
					height: 50px;
				}

				.mat-form-field {
					margin-bottom: 3px;
				}

				.mat-form-field-infix {
					border-top: 0.6em solid transparent;
				}

				.mat-form-field-flex {
					height: 48px;
				}
			}
		}

		.mat-flat-button {
			.mat-icon {
				width: 20px;
				height: 20px;
			}
		}

		.mat-icon-button {
			color: var(--text-light);
		}

		.mat-paginator {
			height: 100%;

			&-outer-container {
				height: 100%;
			}
		}
	}
}

// TABLES
.mat-sort-header-content {
	user-select: none;
	text-transform: uppercase;
	font-size: 12px;
}

.mat-column-description-content {
	max-width: 500px;
	text-overflow: ellipsis;
	overflow: hidden;
}

th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
	padding-right: 8px !important;
}

// BACKLINK
.backlink {
	display: flex;
	align-items: center;
	margin: -1rem 0 1.5rem -8px;
	text-decoration: none;
	color: var(--text-light) !important;

	.mat-icon {
		width: 22px;
		height: 22px;
	}
	span {
		font: 500 14px var(--font-default);
	}
}
