// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button,
.mat-button {
	padding: 0 1.15em;
	margin: 0 0.65em;
	min-width: 3em;
	line-height: 36.4px;

	.mat-icon {
		margin-right: 0.25em;
	}
}
