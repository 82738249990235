@use '@angular/material' as mat;

// Search form
.search-form {
	&__top {
		display: grid;
		grid-template-columns: 1fr;
		gap: 20px;
		width: min(100%, 630px);
		margin: 0 auto;
		padding: 20px;
		background-color: #fff;
		border-radius: 4px;
		z-index: 2;

		@include media('>=860px') {
			grid-template-columns: 1fr 1fr;
			gap: 40px;
			margin: 0;
		}

		.mat-form-field {
			&::after {
				content: '';
				display: block;
				position: absolute;
				bottom: -10px;
				right: -20px;
				width: calc(100% + 40px);
				height: 1px;
				background-color: rgba(0, 0, 0, 0.12);

				@include media('>=860px') {
					top: -20px;
					right: -20px;
					bottom: auto;
					width: 1px;
					height: calc(100% + 40px);
				}
			}
		}

		.mat-form-field-wrapper {
			margin: 0 !important;
			padding: 0;
		}

		.mat-flat-button {
			height: 57px;
			margin-top: 10px;

			@include media('>=860px') {
				margin-top: 0;
			}
		}

		.mat-form-field-appearance-outline .mat-form-field-outline-gap {
			border-width: 0 !important;
		}

		.mat-form-field-appearance-outline .mat-form-field-flex {
			padding: 0;
		}

		.mat-form-field-appearance-outline .mat-form-field-outline-start,
		.mat-form-field-appearance-outline .mat-form-field-outline-end,
		.mat-form-field-infix,
		.mat-form-field-outline-gap {
			border-color: transparent;
		}

		.mat-form-field-outline-thick .mat-form-field-outline-start,
		.mat-form-field-outline-thick .mat-form-field-outline-end,
		.mat-form-field-outline-thick .mat-form-field-outline-gap {
			border-width: 0 !important;
		}

		.mat-form-field-outline-thick .mat-form-field-outline-start,
		.mat-form-field-outline-thick .mat-form-field-outline-end,
		.mat-form-field-outline-thick .mat-form-field-outline-gap {
			border-width: 0 !important;
		}

		.mat-form-field-outline-thick .mat-form-field-outline-start,
		.mat-form-field-outline-thick .mat-form-field-outline-end,
		.mat-form-field-outline-thick .mat-form-field-outline-gap {
			border-width: 0 !important;
		}

		.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
		.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label,
		.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
			transform: translateY(-1.1em) scale(0.75);
		}
	}

	&__filter {
		&-wrapper {
			display: grid;
			grid-template-columns: 1fr;
			gap: 0.5rem;

			@include media('>=600px') {
				grid-template-columns: auto 1fr;
				gap: 2rem;
				margin-top: 1rem;
			}

			@include media('>=900px') {
				grid-template-columns: auto 1fr 1fr;
			}

			@include media('>=1300px') {
				grid-template-columns: auto 1fr 1fr 1fr;
			}
		}

		.mat-form-field-outline {
			@include media('>=860px') {
				height: 64px;
			}
		}

		.mat-form-field-wrapper {
			padding-bottom: 0;
		}

		.mat-slide-toggle {
			margin: 1.5rem 0 1.2rem 0;
		}

		.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
			transform: translateY(0);
		}
	}
}

// Search results
.search-results {
	.mat-card {
		padding: 0;
	}

	&--table__header {
		p,
		strong {
			font-size: clamp(1rem, 0.8125rem + 0.8333vw, 1.25rem);
		}
	}

	&--table__row {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-areas:
			'b b b'
			'c c c'
			'd e f';
		gap: 1rem;
		align-items: center;
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
		padding: 0.75rem 20px;

		@include media('>=500px') {
			grid-template-columns: min-content;
			grid-template-areas:
				'a b c'
				'd e f';
			column-gap: 2rem;
		}

		@include media('>=1024px') {
			grid-template-columns: max-content 1fr 1fr min-content min-content max-content;
			grid-template-areas: 'a b c d e f';
		}

		@include media('>=1200px') {
			gap: 3rem;
		}

		&:last-of-type {
			border-bottom: none;
		}
	}

	&--table__column {
		&-image {
			grid-area: a;
			display: none;

			@include media('>=500px') {
				display: grid;
			}

			@include media('>=1024px') {
				width: 80px;
			}

			@include media('>=1200px') {
				width: auto;
			}
		}

		&-logo {
			grid-area: b;
			line-height: 1.3;

			span {
				font-size: inherit;
			}

			img {
				margin-bottom: 0.25rem;
			}
		}

		&-description {
			grid-area: c;
			font-style: italic;
			font-size: 14px;

			@include media('>=1200px') {
				font-size: var(--text-size-base);
			}
		}

		&-region {
			grid-area: d;
			text-align: center;
			font-size: var(--text-size-tiny);
			line-height: 1.3;

			@include media('>=1024px') {
				width: 70px;
			}

			@include media('>=1200px') {
				width: 100px;
			}

			.location {
				font-weight: 500;
			}

			.map {
				width: 44px;
				height: 60px;
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				margin: 0 auto 0.25rem auto;

				&-international {
					width: 70px;
					background-image: url('/assets/images/maps/international.svg');
				}
				&-deutschland {
					background-image: url('/assets/images/maps/deutschland.svg');
				}
				&-baden-wurttemberg {
					background-image: url('/assets/images/maps/baden-wuerttemberg.svg');
				}
				&-bayern {
					background-image: url('/assets/images/maps/bayern.svg');
				}
				&-berlin {
					background-image: url('/assets/images/maps/berlin.svg');
				}
				&-brandenburg {
					background-image: url('/assets/images/maps/brandenburg.svg');
				}
				&-bremen {
					background-image: url('/assets/images/maps/bremen.svg');
				}
				&-hamburg {
					background-image: url('/assets/images/maps/hamburg.svg');
				}
				&-hessen {
					background-image: url('/assets/images/maps/hessen.svg');
				}
				&-mecklenburg-vorpommern {
					background-image: url('/assets/images/maps/mecklenburg-vorpommern.svg');
				}
				&-niedersachsen {
					background-image: url('/assets/images/maps/niedersachsen.svg');
				}
				&-nordrhein-westfalen {
					background-image: url('/assets/images/maps/nordrhein-westfalen.svg');
				}
				&-rheinland-pfalz {
					background-image: url('/assets/images/maps/rheinland-pfalz.svg');
				}
				&-saarland {
					background-image: url('/assets/images/maps/saarland.svg');
				}
				&-sachsen-anhalt {
					background-image: url('/assets/images/maps/sachsen-anhalt.svg');
				}
				&-sachsen {
					background-image: url('/assets/images/maps/sachsen.svg');
				}
				&-schleswig-holstein {
					background-image: url('/assets/images/maps/schleswig-holstein.svg');
				}
				&-thueringen {
					background-image: url('/assets/images/maps/thueringen.svg');
				}
			}
		}

		&-price {
			grid-area: e;
			grid-template-rows: 1fr;
			line-height: 1.4;
			white-space: nowrap;

			@include media('>=1024px') {
				justify-content: flex-end;
				text-align: right;
			}

			.availability {
				color: #47a81b;
				font-size: 12px;
				font-weight: 500;

				b {
					font-size: 29px;
					line-height: 0;
					vertical-align: middle;
				}
			}

			.price {
				font-size: 18px;
			}

			.tax-shipping {
				color: var(--text-light);
				font-size: 12px;
			}
		}

		&-button {
			display: flex;
			grid-area: f;
			justify-content: flex-end;

			.mat-flat-button {
				@include media('<500px') {
					min-width: unset;
					padding: 0 !important;
					width: 42px;
					height: 42px;
				}
			}

			.mat-icon {
				@include media('<500px') {
					margin: 0;
				}

				& + span {
					display: none;

					@include media('>=500px') {
						display: inline-block;
					}
				}
			}
		}
	}
}
