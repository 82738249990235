///
/// Footer
///

.footer {
	background-color: var(--bg-grey);

	> .container {
		display: grid;
		grid-template-columns: 1fr;
		padding: 3rem 0;

		@include media('>=600px') {
			grid-template-columns: none;
			grid-template-areas:
				'a a'
				'b c'
				'd e';
		}

		@include media('>=1000px') {
			grid-template-areas:
				'a a a a'
				'b c d e';
			grid-template-columns: repeat(4, minmax(0, 1fr));
			justify-items: center;
		}

		@include media('>=1400px') {
			grid-template-areas: 'a b c d e';
			grid-template-columns: repeat(5, minmax(0, 1fr));
		}

		> div {
			margin: 0 9vw;
			padding-top: 1.5rem;

			@include media('>=600px') {
				margin: 0;
			}

			@include media('>=1400px') {
				padding-top: 0;
			}
		}
	}

	&-checkout {
		.container {
			display: flex;
			flex-direction: column;

			@include media('>=800px') {
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
			}

			ul {
				display: flex;
				flex-direction: column;
				margin-bottom: 2rem;

				@include media('>=800px') {
					flex-direction: row;
					justify-content: space-between;
					gap: 4rem;
					margin-bottom: 0;
				}
			}
		}
	}

	.mat-h4 {
		font-family: var(--font-default);
		font-size: 1rem;
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		cursor: pointer;

		li {
			margin: 0.75rem 0;

			a {
				display: block;
				color: var(--text-lightest);
				font-size: 0.875rem;
				text-decoration: none;

				&:active,
				&:hover,
				&:focus {
					color: var(--primary-color);
				}

				.mat-icon {
					vertical-align: middle;
				}
			}
		}
	}

	&--shopvote {
		display: inherit;
		justify-content: center;

		@include media('>=600px') {
			grid-area: a;
		}

		img {
			mix-blend-mode: darken;
		}
	}

	&--contact {
		@include media('>=600px') {
			grid-area: b;
		}
	}

	&--customer-service {
		@include media('>=600px') {
			grid-area: c;
		}
	}

	&--information {
		@include media('>=600px') {
			grid-area: d;
		}
	}

	&--legal {
		@include media('>=600px') {
			grid-area: e;
		}
	}

	&--closing {
		background-color: #ebebeb;

		.container {
			padding: 0.75rem 0;
			text-align: center;

			span {
				color: #808080;
				font-size: var(--text-size-small) !important;
			}
		}
	}
}
