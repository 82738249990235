///
/// LOADING
///

.loading {
	svg {
		width: 210px;
		margin-bottom: 1rem;
		fill: var(--text-dark);
		opacity: 0.25;
		transform: translateX(8px);
	}

	.wrapper {
		position: relative;
		display: flex;
		justify-content: center;
		height: 4rem;
		padding-top: 1rem;
		opacity: 0.8;
	}

	.spinner {
		width: 2rem;
		height: 2rem;
		border-top: 3px solid var(--primary-color);
		border-right: 3px solid transparent;
		border-radius: 50%;
		animation: rotation 0.8s linear infinite;
	}

	@keyframes rotation {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
}
