///
/// Alerts
///

.alert {
	display: grid;
	grid-template-areas:
		'a b b'
		'c c c';
	align-items: center;
	gap: 1rem;
	margin: 0 0 20px 0;
	padding: 20px;
	border-radius: 4px;
	border-width: 1px;
	border-style: solid;

	@include media('>=700px') {
		grid-template-areas: unset;
		grid-template-columns: min-content auto min-content;
	}

	&--icon {
		grid-area: a;
		align-self: flex-start;

		@include media('>=700px') {
			grid-area: auto;
			align-self: center;
		}

		@include media('>=1024px') {
		}
	}

	&--text {
		grid-area: b;

		@include media('>=700px') {
			grid-area: auto;
		}

		b,
		u,
		i {
			font-size: inherit;
		}
	}

	&--button.mat-button {
		grid-area: c;
		justify-self: flex-start;
		width: auto;
		margin: -10px 0 -10px 15px;

		@include media('>=700px') {
			grid-area: auto;
			margin: -10px 0;
		}

		.mat-icon {
			color: var(--text-dark);
		}
	}

	@mixin alert-variant($color, $color-rgb, $color-bg) {
		border-color: rgba($color-rgb, 0.4);
		background: $color-bg;

		.mat-icon {
			color: $color;
		}
	}

	$variants: error, warning, info, success;
	@each $variant in $variants {
		&--#{$variant} {
			@include alert-variant(var(--color-#{$variant}), var(--color-#{$variant}-rgb), var(--color-#{$variant}-bg));
		}
	}
}
