///
/// Geschenkzeitung.de Style System
/// @author Mark Herpich
///

///
/// GLOBAL INCLUDES
///

@import 'theme';

@import 'breakpoints';
@import 'alerts';
@import 'cart';
@import 'content';
@import 'dialog';
@import 'footer';
@import 'forms';
@import 'inputs';
@import 'loading';
@import 'search';
@import 'sidenav';
@import 'status';
@import 'typography';

///
/// ROOT VARIABLEs
///

:root {
	// FONT-SIZES
	--text-size-tiny: 12px;
	--text-size-small: clamp(0.75rem, 0.6563rem + 0.4167vw, 0.875rem); // 12px - 14px
	--text-size-base: 16px;
	--text-size-larger: clamp(1rem, 0.9063rem + 0.4167vw, 1.125rem); // 16px - 18px
	--text-size-large: 36px;
	--text-size-huge: 46px;

	--text-size-scaler: 1vw;

	// FONT-FAMILIES
	--font-default: 'Fira Sans', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
	--font-headings: Bitter, 'Georgia', Times, serif;

	// VIEWPORT UNITS
	--vh: 1vh;

	// MISC
	--header-height: 60px;
	--d: 350ms;
	--e: cubic-bezier(0.55, 0.01, 0.52, 1);

	// MATERIAL THEMING
	--accent-color: #148fcc;
	--accent-lighter-color: #b9ddf0;
	--accent-darker-color: #0b72b9;
	--primary-color: #880000;
	--primary-lighter-color: #dbb3b3;
	--primary-darker-color: #6b0000;
	--bg-beige: #efeade;
	--bg-grey: #f8f8f8;
	--text-dark: $dark-text;
	--text-light: #7a8077;
	--text-lightest: #8b9187;

	// ALERT COLORS
	--color-info: #009ad4;
	--color-info-rgb: 0, 154, 212;
	--color-info-bg: #f0f9fd;
	--color-warning: #ff873c;
	--color-warning-rgb: 255, 135, 60;
	--color-warning-bg: #fff8f3;
	--color-error: #f0506e;
	--color-error-rgb: 240, 80, 110;
	--color-error-bg: #fef4f6;
	--color-success: #47a81b;
	--color-success-rgb: 71, 168, 27;
	--color-success-bg: #f4faf1;

	// STATUS COLORS
	--color-blue: #009ad4;
	--color-blue-bg: #f0f9fd;
	--color-orange: #d69718;
	--color-orange-bg: #fff5e1;
	--color-red: #e9573f;
	--color-red-bg: #fcf2f0;
	--color-green: #70bd1e;
	--color-green-bg: #f2ffe4;
	--color-mint: #37bc9b;
	--color-mint-bg: #e8fcf7;
	--color-grey: #656d78;
	--color-grey-bg: #f3f3f3;
	--color-purple: #967adc;
	--color-purple-bg: #f4f1fd;
}

///
/// GLOBAL DEFINITIONS
///

*,
*::before,
*::after {
	box-sizing: border-box;
}

:focus {
	outline-offset: 0.75ch;
	outline-color: var(--primary-color);
}

::selection {
	background-color: var(--primary-color);
	color: white;
}

::marker {
	color: var(--primary-color);
}

body,
html {
	position: relative;
	overflow: hidden;
	height: 100vh; // fallback option
	height: calc(var(--vh, 1vh) * 100);
	margin: 0;
	padding: 0;
	font-size: var(--text-size-base);
	@extend .mat-body-1;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: var(--text-dark);
	background-color: #fff;
}

figure {
	margin: 0;
}

img {
	display: block;
	height: auto;
	margin: 0;
}

.mat-cell img {
	display: inline;
}

main {
	position: relative;
}

///
/// MATERIAL OVERRIDES
///

[class^='mat-'],
[class^='mat-'] > * {
	font-family: var(--font-default) !important;
}

.mat-drawer-container {
	color: var(--text-dark) !important;
}

.mat-drawer-content {
	min-height: 100vh;
	min-height: calc(var(--vh) * 100);
}

.mat-progress-bar {
	position: fixed !important;
}

button {
	.mat-spinner {
		width: 20px !important;
		height: 20px !important;
		display: inline-block !important;
		margin-right: 6px;
		svg {
			width: 20px !important;
			height: 20px !important;
		}
	}
}

.mat-warn:not(.mat-icon, .mat-button-disabled) {
	background-color: var(--color-red) !important;
}

.mat-success:not(.mat-icon, .mat-button-disabled) {
	background-color: var(--color-green) !important;
	color: white !important;
}

.mat-success.mat-icon {
	color: var(--color-green) !important;
}

.hidden {
	display: none !important;
}

.mat-dialog-container {
	position: relative;

	.dialog-progress {
		position: absolute !important;
		top: 0;
		left: 0;
	}
}

.mat-flat-button.mat-default,
.mat-button.mat-default {
	background-color: #f3f3f3;
}

.mat-dialog-actions .mat-button-base + .mat-button-base {
	margin-left: 1rem !important;
}

.mat-button-disabled {
	background-color: rgba(0, 0, 0, 0.12) !important;
	color: rgba(0, 0, 0, 0.26) !important;
	pointer-events: none;
	cursor: default;

	&.mat-icon-button {
		background-color: transparent !important;
	}
}

// SCROLLBAR STYLING IN DRAWER

::-webkit-scrollbar-track {
	background-color: transparent;
	border-radius: 50%;
}

::-webkit-scrollbar {
	width: 5px;
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	background-color: #bbbbbb;
}

// https://github.com/angular/components/issues/24614
.mat-tooltip-hide {
	display: none !important;
}

.mat-dialog-container .cdk-drag-handle {
	cursor: move;
}
