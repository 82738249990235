///
/// STATUS BADGES
///

.status {
	font-size: 11px;
	font-weight: 500;
	text-transform: uppercase;
	padding: 5px 10px 4px 10px;
	border-radius: 20px;
	white-space: nowrap;

	@mixin status-variant($color, $color-bg) {
		color: $color;
		background: $color-bg;
	}

	$variants: 'red', 'orange', 'blue', 'green', 'mint', 'grey', 'purple';
	@each $variant in $variants {
		&--#{$variant} {
			@include status-variant(var(--color-#{$variant}), var(--color-#{$variant}-bg));
		}
	}
}
