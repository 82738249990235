@use '@angular/material' as mat;

//
// SHOPPING CART
//

.shopping-cart {
	padding-bottom: 5rem;

	&--empty {
		display: grid;
		grid-template-columns: 1fr;
		place-items: center;
		text-align: center;

		button {
			margin-top: 2rem;
		}
	}

	.mat-h1 {
		margin: 2rem 0 3rem 0;
	}

	&--grid {
		display: grid;
		grid-template-columns: 1fr;
		gap: 2rem;

		@include media('>=1150px') {
			grid-template-columns: 2fr minmax(min-content, 1fr);
		}

		@include media('>=1300px') {
			grid-template-columns: 2.5fr minmax(min-content, 1fr);
		}
	}

	.mat-card {
		@include mat.elevation(1);
		padding: 1.5rem;

		@include media('>=1024px') {
			padding: 2rem;
		}

		&-title-group {
			margin: -1.5rem -1.5rem 0 -1.5rem !important;
			padding: 1rem 1.5rem;
			background-color: var(--bg-grey);

			@include media('>=1024px') {
				margin: -2rem -2rem 0 -2rem !important;
				padding: 1rem 2rem;
			}

			> div {
				width: 100%;
			}
		}

		&-title,
		h2 {
			margin: 0;
		}

		h2 {
			font-family: var(--font-default);
			font-size: var(--text-size-larger);
			text-align: center;
		}

		&-content {
			display: grid;
			grid-template-columns: 1fr;
			gap: 0.5rem;
		}

		.mat-divider {
			position: relative;
			margin: 0.5rem 0;
		}
	}

	&--content {
		.mat-card-title {
			h2 {
				@include media('>=600px') {
					text-align: left;
				}
			}
		}

		&__table {
			display: grid;
			grid-template-columns: 1fr;

			&-row,
			&-row-recommendations {
				display: grid;
				gap: 1rem;
				padding: 1rem 0;

				@include media('>=1024px') {
					grid-template-columns: max-content 1fr max-content;
					grid-template-areas: 'a b c' !important;
				}
			}

			&-row {
				display: grid;
				grid-template-areas:
					'a c'
					'b b';
				gap: 1rem;
				padding: 1rem 0;

				@include media('>=1024px') {
					gap: 2rem;
				}

				@include media('>=1200px') {
					gap: 3rem;
				}
			}

			&-row-recommendations {
				grid-template-areas:
					'b b'
					'a c';
				width: calc(100% - 40px);
				justify-self: flex-end;

				@include media('>=500px') {
					width: calc(100% - 90px);
				}

				@include media('>=1024px') {
					gap: 1rem;
					width: calc(100% - 60px);
				}

				@include media('>=1200px') {
					gap: 2rem;
					width: calc(100% - 182px);
				}

				.shopping-cart--content__name {
					margin-bottom: 0.5rem;
					font-size: var(--text-size-base);
				}

				.shopping-cart--content__price {
					font-weight: normal;
				}

				a {
					color: var(--accent-color);

					&:visited,
					&:focus,
					&:active {
						color: var(--accent-color);
					}
				}

				.shopping-cart--content__description {
					font-size: var(--text-size-small);
				}
			}

			&-row + .shopping-cart--content__table-row {
				border-top: 1px solid rgba(0, 0, 0, 0.12);
			}
		}

		&__image {
			grid-area: a;
			width: max-content;
		}

		&__textwrapper {
			grid-area: b;
		}

		&__price-wrapper {
			grid-area: c;
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
			align-items: flex-end;
		}

		&__name,
		&__price {
			font-size: var(--text-size-larger);
			font-weight: 500;
		}

		&__action {
			margin-right: -11px !important;
			color: var(--text-lightest);
		}
	}

	&--xselling {
		margin: 3rem 0 1rem 0;

		.mat-card {
			display: grid !important;
			background-color: var(--bg-grey);

			.swiper {
				width: 100%;
			}
		}
	}
}
