///
/// FORMS
///

/* Change Autocomplete styles in Chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	border: none;
	-webkit-text-fill-color: var(--text-dark);
	background-color: transparent;
}

// Radio buttons
.gz-radio-group {
	#gz-radio-label {
		display: block;
		margin: 1rem 0;

		@include media('>=1024px') {
			margin: 3.25rem 0 1rem 0;
		}
	}

	.mat-radio-group {
		display: grid;
		grid-template-columns: 1fr;
		gap: 1rem;
	}

	.mat-radio-label {
		padding: 0.75rem;
		white-space: normal;

		@include media('>=600px') {
			padding: 1rem;
		}
	}

	&__radio-button {
		display: flex;
		border: 1px solid #ccc;
		border-radius: 4px;

		&.mat-radio-checked {
			border-color: var(--accent-color);
			box-shadow: 0px 2px 4px -1px rgb(20 143 204 / 20%), 0px 4px 5px 0px rgb(20 143 204 / 14%), 0px 1px 10px 0px rgb(20 143 204 / 12%);
		}

		&.tipp {
			position: relative;
			overflow: hidden;

			&::before {
				content: 'Unser Tipp!';
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				padding: 0.5rem 4rem;
				font-family: var(--font-headings);
				font-weight: 600;
				color: #fff;
				background-color: var(--primary-color);
				transform: rotate(32deg) translate(28%, -54%);
			}

			&__radio-button-headline {
				padding-right: 5rem;
			}

			&__radio-button-description {
				padding-right: 2rem;
			}
		}

		.mat-radio-container {
			align-self: flex-start;
			margin: 2px 0.5rem 0 0;

			.mat-focus-indicator {
				display: none;
			}
		}
	}

	.gz-radio-group__radio-button-grid {
		display: grid;
		gap: 0.5rem;
		grid-template-areas:
			'a'
			'b'
			'c'
			'd';

		@include media('>=500px') {
			grid-template-areas:
				'c a'
				'c b'
				'c d';
		}
	}

	.gz-radio-group__radio-button-headline {
		grid-area: a;
		font-size: var(--text-size-base);
		font-weight: 500;
	}

	.gz-radio-group__radio-button-description {
		grid-area: b;
		font-size: 14px;
	}

	.gz-radio-group__radio-button-image {
		grid-area: c;
		width: max-content;

		@include media('>=500px') {
			margin-right: 0.5rem;
		}
	}

	.gz-radio-group__radio-button-price {
		grid-area: d;
		font-size: var(--text-size-larger);
		text-align: right;
	}
}

// Radio buttons in Checkout
.gz-radio-group {
	&--payments,
	&--shipping {
		.gz-radio-group__radio-button-grid {
			display: grid;
			grid-template-columns: 1fr;
			gap: 0.5rem;
			grid-template-areas:
				'a a'
				'b b'
				'c d';

			@include media('>=500px') {
				grid-template-columns: max-content auto auto;
				align-items: center;
				gap: 0;
				column-gap: 1rem;
				grid-template-areas:
					'c a d'
					'c b d';
			}

			@include media('>=800px') {
				column-gap: 2rem;
			}
		}

		.gz-radio-group__radio-button-image {
			margin: 0;
			width: auto;
			max-width: 120px;

			@include media('>=500px') {
				max-width: 100px;
			}

			@include media('>=800px') {
				max-width: none;
			}
		}

		.mat-radio-container {
			@include media('>=500px') {
				align-self: center;
				margin: 0 0.5rem 0 0;
			}
		}

		.mat-radio-label {
			display: grid;
			grid-template-columns: max-content auto;
		}

		.gz-radio-group__radio-button-price {
			white-space: nowrap;
		}
	}

	&--payments {
		.gz-radio-group__radio-button-grid {
			gap: 0;
			column-gap: 0.5rem;
			grid-template-areas:
				'a c'
				'b b';

			@include media('>=500px') {
				grid-template-columns: 1fr;
				grid-template-areas:
					'a c'
					'b b';
			}
		}

		.gz-radio-group__radio-button-headline {
			grid-area: a;
			font-size: var(--text-size-larger);
			font-weight: 400;

			span {
				display: inline-block;
				margin-left: 0.5rem;
				color: var(--text-light);

				@include media('<500px') {
					display: block;
					margin: 0;
				}
			}
		}

		.gz-radio-group__radio-button-description {
			display: none;
			grid-area: b;
		}

		.gz-radio-group__radio-button-image {
			grid-area: c;
			display: flex;
			flex-direction: row;
			gap: 0.7rem;

			> img:not(:first-of-type) {
				display: none;

				@include media('>=1200px') {
					display: block;
				}
			}
		}

		.mat-radio-checked {
			.gz-radio-group__radio-button-grid {
				grid-template-areas:
					'a c'
					'b b';

				@include media('>=500px') {
					grid-template-areas:
						'a c'
						'b c';
				}
			}

			.gz-radio-group__radio-button-headline {
				font-weight: 500;
			}

			.gz-radio-group__radio-button-description {
				display: block;
			}
		}
	}
}

// Material Input overrides
.mat-form-field-appearance-outline {
	.mat-form-field-infix {
		padding: 0.75em 0 1.25em 0;
	}

	.mat-form-field-label-wrapper {
		top: -1em;
		padding-top: 1em;
	}

	.mat-form-field-label {
		top: 1.9em;
	}

	&.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
	&.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label,
	&.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
		transform: translateY(-1.6em) scale(0.75);
	}

	.mat-form-field-outline-thick .mat-form-field-outline-start,
	.mat-form-field-outline-thick .mat-form-field-outline-end,
	.mat-form-field-outline-thick .mat-form-field-outline-gap {
		border-width: 1px !important;
	}

	.mat-form-field-outline-thick .mat-form-field-outline-start,
	.mat-form-field-outline-thick .mat-form-field-outline-end,
	.mat-form-field-outline-thick .mat-form-field-outline-gap {
		border-width: 1px !important;
	}

	.mat-form-field-outline-thick .mat-form-field-outline-start,
	.mat-form-field-outline-thick .mat-form-field-outline-end,
	.mat-form-field-outline-thick .mat-form-field-outline-gap {
		border-width: 1px !important;
	}

	&.mat-focused .mat-form-field-outline-thick {
		color: var(--text-dark);
	}
}

.mat-form-field.mat-focused .mat-form-field-label {
	color: var(--text-dark);
}

.mat-button,
.mat-flat-button,
.mat-stroked-button {
	height: 56px;
	padding: 0 24px !important;

	@include media('<400px') {
		width: 100%;
	}
}

.mat-stroked-button:not(.mat-button-disabled) {
	border-color: var(--primary-color);
	border-width: 2px;
}

.mat-datepicker-toggle {
	display: block;
	margin: 0 -5px 0 -6px;
}

.mat-form-field-prefix,
.mat-form-field-suffix {
	color: rgba(0, 0, 0, 0.54);
}

.mat-form-field-prefix {
	margin-right: 0.5rem;
}

.mat-slide-toggle-content {
	font-family: var(--font-default);
	font-size: 14px;
}

// SELECT
.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
	transform: none !important;
}

// FILTER
.filter-toggled {
	thead tr:first-of-type {
		th {
			border: none;
		}
	}

	.filter-row {
		display: table-row;
	}
}

.filter-row {
	display: none;

	th {
		vertical-align: top;
	}

	.filter-field {
		display: flex;
		flex-direction: row;
		gap: 1rem;
	}

	.filter,
	.filter-operator {
		.mat-form-field-wrapper {
			margin: 0 !important;
			padding: 0;
		}

		.mat-form-field-flex {
			height: 50px !important;
			margin: 0;
		}

		.mat-form-field-infix {
			padding: 0.5em 0;
		}
	}

	.progress-wrapper {
		.mat-progress-bar {
			bottom: 0 !important;
		}
	}

	.filter {
		width: 100%;

		&.mat-form-field-appearance-outline {
			&.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
			&.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label,
			&.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
				transform: translateY(-1.45em) scale(0.75);
			}

			.mat-form-field-label {
				top: 1.8em;
				font-size: 14px;
			}

			input.mat-input-element,
			.mat-select-min-line {
				font-size: 14px;
			}
		}
	}

	.filter-operator {
		.mat-select-arrow-wrapper {
			display: none;
		}

		.mat-form-field-infix {
			width: 8px;
		}
	}

	th.mat-header-cell:first-of-type,
	td.mat-cell:first-of-type,
	td.mat-footer-cell:first-of-type {
		padding-left: 12px !important;
	}

	td.mat-cell:last-of-type,
	td.mat-footer-cell:last-of-type {
		padding-right: 24px !important;
	}

	th {
		padding: 0 10px 10px 0 !important;
	}
}

.filter-button-column,
.actions-column {
	text-align: right !important;
}

.filter-on {
	color: var(--accent-color);
}

// MODAL FORMS
.mat-dialog-title {
	margin-bottom: 1rem !important;
	font-size: var(--text-size-larger) !important;
}

.mat-dialog-actions {
	padding: 2rem 0 1.5rem 0 !important;
}

.mat-option-text {
	display: flex !important;
	gap: 1rem;
	align-items: center;
}

// BUTTONS
.mat-button,
.mat-flat-button {
	padding: 0.75rem 1rem !important;
	min-width: unset;
	line-height: 1 !important;
	height: auto;

	.mat-button-wrapper {
		font-size: var(--text-size-small);
	}

	.mat-icon {
		width: 20px;
		height: 20px;
	}
}

.mat-simple-snackbar-action {
	.mat-button {
		padding: 8px 16px !important;
	}
}

.mat-button-toggle-group {
	flex-direction: column !important;
	width: 100%;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
	border-left: none !important;
	border-top: solid 1px #e0e0e0 !important;
}

.form-grid {
	display: grid !important;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	column-gap: 1.5rem;
	margin-top: 1.5rem;
	overflow: hidden;

	&.order-search {
		grid-template-columns: 3fr 1fr;
	}

	&:first-of-type {
		margin-top: 0;
	}

	.editor {
		grid-column: span 2;
		margin-bottom: 2rem;
	}

	.form-subgrid {
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		gap: 1rem;
	}

	.mat-form-field {
		width: 100%;
	}
}

.picking-dialog {
	.form-grid {
		grid-template-columns: 2fr 1fr;
	}
}

.profile-picture {
	display: inline-flex;
	vertical-align: middle;
	margin-right: 0.5rem;

	img {
		display: block;
		width: 26px;
		height: 26px;
		margin: 0 auto;
		padding: 0;
		object-fit: cover;
		border-radius: 50%;
		border: 1px solid #c2c2c2;
		background: #f8f8f8;
	}
}

.nav-second .profile-picture {
	margin-right: 0;
}

// MAT TOOLBAR
.mat-toolbar {
	.mat-form-field-appearance-outline .mat-form-field-wrapper {
		margin: 0 !important;
		padding: 0 !important;

		.mat-form-field-flex {
			height: 50px;
		}

		.mat-form-field-infix {
			padding: 0.5rem 0;
		}

		.mat-form-field-label {
			top: 1.5rem;
		}
	}

	.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
		transform: translateY(-1.25em) scale(0.7);
	}

	.progress-wrapper .mat-progress-bar {
		bottom: 0 !important;
	}
}

.mat-toolbar.sticky {
	position: fixed;
	bottom: 0;
	left: 65px;
	right: 0;
	width: auto;
	background-color: #fff;
	box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);
	z-index: 1;

	&.drawer-opened {
		left: 321px;
	}

	.spacer {
		flex: 1 1 auto;
	}

	button + button {
		margin-left: 1rem;
	}
}

// TABS
.mat-tab-body {
	padding-top: 1.5rem;
	padding-bottom: 5rem;
}

// CHECKBOX LIST
.checkbox-list {
	list-style: none;
	padding: 0;
}

.mat-form-field--span {
	grid-column: span 2;
}

.mat-form-field.readonly {
	.mat-form-field-outline-start,
	.mat-form-field-outline-gap,
	.mat-form-field-outline-end {
		border-color: transparent !important;
	}
}

.storage-place {
	font-size: var(--text-size-huge);
}

// Certificate Form Dialog
.certificate-form {
	--paper-width: 21cm;
	--paper-height: 29.7cm;
	--scaling: 0.6;

	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 2rem;

	&--certificate-wrapper {
		position: relative;
		width: calc(var(--paper-width) * var(--scaling));
		height: calc(var(--paper-height) * var(--scaling));
	}

	.certificate {
		&__image {
			--divider-x: calc(var(--paper-width) * var(--scaling));
			--divider-y: calc(var(--paper-height) * var(--scaling));
			position: absolute;
			width: var(--paper-width);
			height: var(--paper-height);
			transform: scale(var(--scaling));
			top: calc(var(--divider-y) * -0.334);
			left: calc(var(--divider-x) * -0.334);
			aspect-ratio: 1/1.414;
			background-image: url('/assets/images/certificate-bg.jpeg');
			background-position: center;

			&--frame {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-image: url('/assets/images/certificate-border.svg');
				background-size: cover;
				background-position: center;
				mix-blend-mode: multiply;
				pointer-events: none;

				&-anniversary {
					background-image: url('/assets/images/certificate-border-anniversary.svg');
				}

				&-xmas {
					background-image: url('/assets/images/certificate-border-xmas.svg');
				}
			}

			&--text {
				display: flex;
				flex-direction: column;
				width: 100%;
				height: 100%;
				align-items: center;

				> * {
					user-select: none;
					max-width: 66%;
					text-align: center;
				}

				&-year,
				&-occasion {
					font-family: 'Algerian', sans-serif;
					color: var(--primary-color);
				}

				&-year {
					font-size: 48pt;
					margin-top: 12.25%; //12.7% in PDF
					margin-left: 2%;
				}

				&-occasion {
					--fs-occasion: 33pt;
					--fs-scaling: 1;
					font-size: calc(var(--fs-occasion) * var(--fs-scaling));
					text-transform: uppercase;
					line-height: 1.1;
					margin-top: 6%; //7% in PDF
					hyphens: auto;
					word-break: keep-all;

					.birthday {
						--fs-occasion: 48pt;
						font-size: calc(var(--fs-occasion) * var(--fs-scaling));
					}

					.silver {
						--fs-occasion: 42pt;
						font-size: calc(var(--fs-occasion) * var(--fs-scaling));
					}

					.golden {
						--fs-occasion: 39pt;
						font-size: calc(var(--fs-occasion) * var(--fs-scaling));
					}

					.diamond {
						--fs-occasion: 32pt;
						font-size: calc(var(--fs-occasion) * var(--fs-scaling));
						white-space: nowrap;
						hyphens: none;
					}

					.xmas {
						margin-top: 12%;
						--fs-occasion: 39pt;
						font-size: calc(var(--fs-occasion) * var(--fs-scaling));
					}
				}

				&-for,
				&-default,
				&-partner span {
					font-family: 'Times New Roman', serif;
				}

				&-for {
					font-size: 14pt;
					font-style: italic;
					margin-top: 1.5%; //2% in PDF
				}

				&-giftee,
				&-giftee-birthname,
				&-giftee-addname,
				&-partner,
				&-partner-birthname,
				&-partner-addname,
				&-date,
				&-date > *,
				&-message,
				.rings {
					font-family: 'Lucida Calligraphy', sans-serif;
				}

				&-giftee {
					--fs-giftee: 24pt;
					margin-top: 1.5%; //2% in PDF
					font-size: calc(var(--fs-giftee) * var(--fs-scaling));

					&-addname {
						--fs-giftee: 24pt;
						margin-top: 0;
						font-size: calc(var(--fs-giftee) * var(--fs-scaling));
						line-height: 1.2;
					}
				}

				&-partner {
					--fs-partner: 24pt;
					margin-top: 1.5%; //2% in PDF
					font-size: calc(var(--fs-partner) * var(--fs-scaling));

					span {
						display: block;
						margin: 0 0 4% 0;
						font-size: 16pt;
						font-style: italic;
					}

					&-addname {
						--fs-partner: 24pt;
						margin-top: 0;
						font-size: calc(var(--fs-partner) * var(--fs-scaling));
						line-height: 1.2;
					}
				}

				&-date,
				&-date > * {
					margin-top: 3.5%; //2% in PDF
					font-size: 16pt;
				}

				&-giftee + &-date {
					margin-top: 0;
				}

				&-giftee-birthname {
					--fs-giftee-birthname: 14pt;
					margin-top: -0.5%; // 0 in PDF
					font-size: calc(var(--fs-giftee-birthname) * var(--fs-scaling));
				}

				&-partner-birthname {
					--fs-partner-birthname: 14pt;
					margin-top: -0.1%; // 0 in PDF
					font-size: calc(var(--fs-partner-birthname) * var(--fs-scaling));
				}

				&-default {
					--fs-text: 16pt;
					font-style: italic;
					font-size: calc(var(--fs-text) * var(--fs-scaling));
					line-height: 1.2;
					margin-top: auto;
					max-width: 50%;
				}

				&-message {
					--fs-message: 15pt;
					font-size: calc(var(--fs-message) * var(--fs-scaling));
					color: var(--primary-color);
					margin-top: auto;
					margin-bottom: 20%;
					white-space: pre-line;
				}

				.rings {
					display: inline-block;
					margin-right: 0.5rem;
					font-size: inherit;
					letter-spacing: -0.5em;
				}
			}
		}

		&__form {
			.mat-slide-toggle {
				display: block;
				margin-bottom: 1.5rem;

				& + .textarea {
					width: 100%;
				}
			}
		}

		&__description {
			margin: 0 0 2rem 0;
		}
	}
}

.fs-display {
	&--label {
		display: flex;
		justify-content: space-between;
		margin-bottom: 0.25rem;
		padding: 0 2px;

		> label:first-of-type {
			color: #636363;
		}

		> label:last-of-type {
			color: var(--accent-color);

			small {
				font-weight: 500 !important;
			}
		}
	}
}

.font-sizes-grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1.5rem;
	margin-top: 1rem;
	padding-right: 2rem;
}

/* === range theme and appearance === */
input[type='range'] {
	font-size: 1.5rem;
	width: 99%;
}

input[type='range'] {
	color: var(--accent-color);
	--thumb-height: 0.5em;
	--thumb-width: 0.5em;
	--clip-edges: 0.0125em;
	--track-height: 0.125em;
	--track-color: rgba(0, 0, 0, 0.2);
	--brightness-hover: 110%;
	--brightness-down: 90%;
}

/* === range commons === */
input[type='range'] {
	position: relative;
	background: #fff0;
	overflow: hidden;
}

input[type='range']:active {
	cursor: grabbing;
}

input[type='range']:disabled {
	filter: grayscale(1);
	opacity: 0.3;
	cursor: not-allowed;
}

/* === WebKit specific styles === */
input[type='range'],
input[type='range']::-webkit-slider-runnable-track,
input[type='range']::-webkit-slider-thumb {
	-webkit-appearance: none;
	transition: all ease 100ms;
	height: var(--thumb-height);
}

input[type='range']::-webkit-slider-runnable-track,
input[type='range']::-webkit-slider-thumb {
	position: relative;
}

input[type='range']::-webkit-slider-thumb {
	--thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
	--clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
	--clip-bottom: calc(var(--thumb-height) - var(--clip-top));
	--clip-further: calc(100% + 1px);
	--box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0 100vmax currentColor;

	width: var(--thumb-width, var(--thumb-height));
	background: linear-gradient(currentColor 0 0) scroll no-repeat left center / 50% calc(var(--track-height) + 1px);
	background-color: currentColor;
	box-shadow: var(--box-fill);
	border-radius: var(--thumb-width, var(--thumb-height));

	filter: brightness(100%);
	clip-path: polygon(100% -1px, var(--clip-edges) -1px, 0 var(--clip-top), -100vmax var(--clip-top), -100vmax var(--clip-bottom), 0 var(--clip-bottom), var(--clip-edges) 100%, var(--clip-further) var(--clip-further));
}

input[type='range']:hover::-webkit-slider-thumb {
	filter: brightness(var(--brightness-hover));
	cursor: grab;
}

input[type='range']:active::-webkit-slider-thumb {
	filter: brightness(var(--brightness-down));
	cursor: grabbing;
}

input[type='range']::-webkit-slider-runnable-track {
	background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center / 100% calc(var(--track-height) + 1px);
}

input[type='range']:disabled::-webkit-slider-thumb {
	cursor: not-allowed;
}

/* === Firefox specific styles === */
input[type='range'],
input[type='range']::-moz-range-track,
input[type='range']::-moz-range-thumb {
	appearance: none;
	transition: all ease 100ms;
	height: var(--thumb-height);
}

input[type='range']::-moz-range-track,
input[type='range']::-moz-range-thumb,
input[type='range']::-moz-range-progress {
	background: #fff0;
}

input[type='range']::-moz-range-thumb {
	background: currentColor;
	border: 0;
	width: var(--thumb-width, var(--thumb-height));
	border-radius: var(--thumb-width, var(--thumb-height));
	cursor: grab;
}

input[type='range']:active::-moz-range-thumb {
	cursor: grabbing;
}

input[type='range']::-moz-range-track {
	width: 100%;
	background: var(--track-color);
}

input[type='range']::-moz-range-progress {
	appearance: none;
	background: currentColor;
	transition-delay: 30ms;
}

input[type='range']::-moz-range-track,
input[type='range']::-moz-range-progress {
	height: calc(var(--track-height) + 1px);
	border-radius: var(--track-height);
}

input[type='range']::-moz-range-thumb,
input[type='range']::-moz-range-progress {
	filter: brightness(100%);
}

input[type='range']:hover::-moz-range-thumb,
input[type='range']:hover::-moz-range-progress {
	filter: brightness(var(--brightness-hover));
}

input[type='range']:active::-moz-range-thumb,
input[type='range']:active::-moz-range-progress {
	filter: brightness(var(--brightness-down));
}

input[type='range']:disabled::-moz-range-thumb {
	cursor: not-allowed;
}

.resubmission-date {
	width: 100%;
	margin-top: 1rem;
}
