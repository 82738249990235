///
/// FONT DECLARATIONS
///

///
/// BITTER (https://fonts.google.com/specimen/Bitter?query=bitter)
/// FIRA SANS (https://fonts.google.com/specimen/Fira+Sans?query=fira+sans)
///

/**
 * The path to the fonts folder, relative to the
 * compiled stylesheet
 *
 * @type String
 */
$font-path: '../fonts/' !default;

/**
  * A list of the project's font faces along with
  * thier associated variants
  *
  * @type List
  * Note: The format of this list is important.
  * Do not miss the trailing comma for single line
  * declarations
  */
$font-faces: (
	'Bitter': (
		'bitter-v19-latin-regular' 400 normal,
		'bitter-v19-latin-600' 600 normal,
		'bitter-v19-latin-700' 700 normal,
		'bitter-v19-latin-italic' 400 italic,
		'bitter-v19-latin-600italic' 600 italic,
		'bitter-v19-latin-700italic' 700 italic,
	),
	'Fira Sans': (
		'fira-sans-v11-latin-regular' 400 normal,
		'fira-sans-v11-latin-italic' 400 italic,
		'fira-sans-v11-latin-500' 500 normal,
		'fira-sans-v11-latin-500italic' 500 italic,
		'fira-sans-v11-latin-600' 600 normal,
		'fira-sans-v11-latin-600italic' 600 italic,
	),
) !default;

/**
  * Generates a complete font face declarations
  * where invoked
  *
  * @type mixin
  *
  * @param $font-family The with which the font family will be called
  * @param $font-path   The path to the fonts directory relative to the compiled stylesheet
  * @param $font-file   The name of the actual font file
  * @param $font-weight The font weight (normal, bold, lighter)
  * @param $font-style  The font style (normal, italic)
  *
  * Example Usage:
  * @include font-face('Open Sans', '../fonts/', 'OpenSans-regular-webfont', bold, italic)
  */
@mixin font-face($font-family, $font-path, $font-file, $font-weight: normal, $font-style: normal) {
	@font-face {
		font-family: $font-family;
		src: url($font-path + $font-file + '.woff2') format('woff2'), url($font-path + $font-file + '.woff') format('woff');
		font-weight: $font-weight;
		font-style: $font-style;
		font-display: swap;
	}
}

/**
  * A loop to run through each font family
  * and print the font face declarations of each
  * variant
  *
  * Dependencies
  * variable - $font-path
  * list     - $font-faces
  * mixin    - font-face
  */
@each $font-family, $font-variants in $font-faces {
	@each $variant in $font-variants {
		$font-path: $font-path !global;
		$font-file: nth($variant, 1);
		$font-weight: nth($variant, 2);
		$font-style: nth($variant, 3);

		@include font-face($font-family, $font-path, $font-file, $font-weight, $font-style);
	}
}

// transfer Material definitions to standard tags
h1 {
	@extend .mat-headline;
	margin-bottom: 2.75rem !important;
}

h2 {
	@extend .mat-title;
}

h3 {
	@extend .mat-subheading-2;
}

h4 {
	@extend .mat-subheading-1;
}

p,
span,
address,
li,
a {
	@extend .mat-body-1;
}

.mat-card {
	font-family: var(--font-default) !important;
}

b,
strong {
	@extend .mat-body-2;
}

small,
caption {
	display: inline-block;
	@extend .mat-caption;
	letter-spacing: normal;
}

ol {
	li {
		& + li {
			margin-top: 1em;
		}
	}
}

a {
	color: var(--primary-color);
	transition: all var(--d) var(--e);

	&:visited,
	&:hover,
	&:active {
		color: var(--primary-color);
	}
}

// Colors for Date indication
.text {
	&-error {
		color: var(--color-error);
	}

	&-info {
		color: var(--color-info);
	}

	&-success {
		color: var(--color-success);
	}

	&-warning {
		color: var(--color-warning);
	}

	&-bold {
		font-weight: 500;
	}
}
