// Sidenav
mat-sidenav[style] {
	visibility: visible !important;
}

.mat-menu-panel.sidenav {
	margin-left: 10px;
}

.mat-drawer {
	transform: none !important;
}

.mat-drawer[style*='visibility: hidden'] {
	display: block !important;
}

.mat-sidenav {
	background-image: url('/assets/images/bg_babylon.jpg');
	background-size: cover;
	background-position: center;

	.mat-icon {
		color: var(--text-light) !important;
	}

	.mat-list {
		display: grid;
		grid-template-columns: 1fr;
		gap: 0.5rem;
	}

	.mat-list-item {
		height: auto !important;

		&-content {
			position: relative;
			align-items: flex-start !important;
			padding: 0 10px 0 16px !important;
			line-height: 1;

			&::before {
				content: '';
				position: absolute;
				inset: 0 10px;
				background-color: #fff;
				border-radius: 4px;
				z-index: -1;
				opacity: 0;
				transition: all 200ms var(--e);
			}

			&:hover {
				&::before {
					opacity: 0.6;
				}
			}

			.mat-icon {
				height: 40px !important;

				&.open-in-new {
					height: 17px !important;
					width: 14px !important;
					margin-top: -8px;
				}
			}
		}

		&.active-link {
			.mat-list-item-content {
				&::before {
					content: '';
					position: absolute;
					inset: 0 10px;
					background-color: #fff;
					border-radius: 4px;
					z-index: -1;
					opacity: 0.6;
				}

				.mat-expansion-panel-body {
					.mat-list-item-content::before {
						display: none;
					}
				}
			}
		}

		&.active-sublink {
			.mat-list-item-content {
				color: var(--primary-color);
				font-weight: 600 !important;
			}
		}
	}

	.mat-slide-toggle {
		height: 37px;
	}

	.mat-list-text {
		height: 48px;
		justify-content: center;
		padding-left: 12px !important;
	}

	.mat-expansion-panel {
		width: 100%;
		background: none !important;

		&-header {
			height: 48px;
			padding: 0 2px 0 13px;

			.mat-content {
				font: inherit !important;
				font-family: var(--font-default) !important;
				font-size: 14px !important;
				font-weight: 500 !important;
			}
		}

		.mat-ripple {
			display: none !important;
		}

		.mat-divider {
			position: relative !important;
			margin: 0.5rem 0 !important;
		}

		.mat-expansion-indicator {
			margin-right: 1rem;

			&::after {
				margin-bottom: 4px;
			}
		}

		&-content {
			a {
				&:hover {
					background: transparent !important;
				}

				span:not(.count) {
					padding: 0.5rem 0 0.5rem 0.5rem !important;
				}
			}

			.mat-list-item-content {
				font: inherit !important;
				font-family: var(--font-default) !important;
				font-size: 14px !important;
			}
		}
	}

	.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled='true']),
	.mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled='true']),
	.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
		background: none !important;
	}
}

.mat-drawer-opened {
	.nav-second img {
		width: 46px !important;
		height: 46px !important;
	}
}

side-nav {
	section {
		height: 100%;
		display: flex;
		flex-direction: column;
	}
}

.mat-expansion-panel-body {
	padding: 0 0 10px 5px !important;

	.mat-list-item {
		&-content::before {
			inset: 0 -6px 0 -6px !important;
		}
	}
}

.nav-second__user {
	img {
		display: block;
		width: 40px;
		height: 40px;
		margin: 0 auto;
		padding: 0;
		object-fit: cover;
		border-radius: 50%;
		border: 1px solid #ddd;
		background: #f8f8f8;
	}
}

.count-wrapper > span {
	justify-content: space-between;
}
