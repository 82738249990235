.mat-dialog-content {
	.mat-tab-body {
		padding-bottom: 0;

		&-content {
			height: auto;
		}
	}

	.mat-tab-label {
		.mat-icon {
			margin-left: 0.5rem;
		}
	}
}

.debt-collection-indicator {
	margin-left: 0.5rem;

	mat-icon {
		width: 20px;
		height: 20px;
		vertical-align: text-bottom;
		color: #ff4033;

		&.successful {
			color: #00c853;
		}
	}
}
